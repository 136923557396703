<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="currentImage" alt="" class="imgItem1" />
        <div class="box1">
          <h1>Nuestro Servicio</h1>
          <ul>
            <li :class="{ clicked: currentImage === importedImg1 }" @click="changeImage(importedImg1)">
              Centro De Llamadas Internacionales
            </li>
            <li :class="{ clicked: currentImage === importedImg2 }" @click="changeImage(importedImg2)">
              Encuesta De Satisfacción Del Consumidor
            </li>
            <li :class="{ clicked: currentImage === importedImg3 }" @click="changeImage(importedImg3)">
              Servicio de reserva promocional
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/easycommu/components/img/3_1.png";
import importedImg2 from "@/page/easycommu/components/img/3_2.png";
import importedImg3 from "@/page/easycommu/components/img/3_3.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      currentImage: importedImg1, // 初始化显示第一张图片
      altText: "图片",
    };
  },
  methods: {
    changeImage(image) {
      this.currentImage = image;
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.imgBox1 {
  display: flex;
  justify-content: center;
  width: 100%;
}

.img1 {
  display: flex; /* 使用 Flexbox 布局 */
  align-items: center; /* 垂直居中对齐 */
  margin-left: 45px;
  margin-right: 45px;
  position: relative; /* 相对定位 */
}

.imgItem1 {
  max-width: 90%;
  margin-top: 20px;
  margin-left: 370px;
  margin-bottom: 100px;
}

.box1 {
  width: 370px; /* 调整 box1 的宽度 */
  height: auto;
  background-color: #ecf0ff;
  border-radius: 10px;
  position: absolute; /* 绝对定位 */
  top: 20px; /* 设置距离顶部的距离 */
  left: -20px; /* 设置距离左侧的距离 */
  padding: 20px; /* 添加内边距 */
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  cursor: pointer; /* 添加指针样式以表示可点击 */
  color: #333; /* 设置默认字体颜色 */
  border-radius: 5px;
  padding: 5px;
}

a {
  text-decoration: none;
}

a:hover {
  color: #ff6600; /* 设置链接悬停时的颜色 */
}

/* 新增样式：点击后的效果 */
li.clicked {
  background-color: #5956E9; /* 点击后背景色为蓝色 */
  color: #fff; /* 点击后字体颜色为白色 */
}
</style>